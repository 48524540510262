<template>
  <div class="footer">
    <div class="footer-contact">
      <SearchDialog
        :dialogApply="dialogApply123"
        @closeModalApply="closeModalApply"
      />
      <div
        v-for="(contactItem, index) in listContact"
        :key="index"
        class="footer-contact-block text-left mb-2"
        :class="[contactItem.title === 'CONTACT' ? 'contact' : '']"
      >
        <Label for="" class="contact__label">{{ contactItem.title }}</Label>
        <div class="contact-content" v-if="contactItem.children">
          <div
            v-for="child in contactItem.children"
            :key="child"
          >
            <a
              v-if="child.title !== '会場検索・お申込みはこちら'"
              :target="child.title === 'メールでお問い合わせ' ? '' : '_blank'"
              :href="child.link"
              class="contact-content__link"
              :class="[child.isClick === false ? 'noClick' : child.isClick === 'email' ? 'email' : '']"
              disabled="disabled"
              >{{ child.title }}</a
            >
            <div
              v-if="child.title === '会場検索・お申込みはこちら'"
              class="contact-content__link apply"
              @click="goToApply">
                {{ child.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterFollowUs :listFollow="listFollow" />
    <div class="text-[#FFF] text-center mb-20 md:mb-12">
      ©️ adval All Rights Reserved
    </div>
  </div>
</template>

<script>
import FooterFollowUs from "@/client/molecules/LayoutHome/FooterFollowUs.vue";
import Label from "@/client/atoms/Label";
import { reactive } from "@vue/reactivity";
import { useI18n } from "vue-i18n";
import { ROUTER_PATH } from "@/client/const";
import SearchDialog from "@/client/molecules/LayoutHome/SearchDialog.vue";
import {ref} from "vue";
import useStore from "@/client/store/Customer";
export default {
  name: "FooterVue",
  components: {
    Label,
    FooterFollowUs,
    SearchDialog,
  },
  setup() {
    const { t } = useI18n();
    const uStore = useStore();
    const listContact = reactive([
      {
        id: 1,
        title: t("footer.footer_contact.guide"),
        children: [
          {
            id: 1,
            title: t("footer.footer_contact.usage_guide"),
            link: `${ROUTER_PATH.USER_GUIDE.FAQ}`,
          },
          {
            id: 2,
            title: t("footer.footer_contact.operating_company"),
            link: `https://adval.jp`,
          },
          {
            id: 3,
            title: t("footer.footer_contact.privacy_policy"),
            link: `https://adval.jp/privacy`,
          },
          {
            id: 4,
            title: t("footer.footer_contact.handling_personal"),
            link: `https://adval.jp/purpose`,
          },
          {
            id: 5,
            title: t("footer.footer_contact.basic_policy_against"),
            link: `https://adval.jp/privacy/hansyakai`,
          },
          {
            id: 6,
            title: t("footer.footer_contact.notation_commercial_transactions"),
            link: `${ROUTER_PATH.SPECIFIED_COMMERCIAL}`,
          },
        ],
      },
      {
        id: 2,
        title: t("footer.footer_contact.link"),
        children: [
          {
            id: 1,
            title: t("footer.footer_contact.sharing"),
            link: `${window.location.origin}/sharing`,
          },
          {
            id: 2,
            title: t("footer.footer_contact.quick-delistyle"),
            link: `https://www.quickdelistyle.com/`,
          },
          {
            id: 3,
            title: t("footer.footer_contact.platinum-delistyle"),
            link: `https://www.platinumstyle-deli.jp/`,
          },
          {
            id: 4,
            title: t("footer.footer_contact.premium-catering"),
            link: `https://premium-catering.jp/`,
          },
        ],
      },
      {
        id: 3,
        title: t("footer.footer_contact.contact"),
        children: [
          {
            id: 1,
            title: t("footer.footer_contact.email_us"),
            link: `${ROUTER_PATH.CONTACT_IQUIRIES}`,
            isClick: `email`,
          },
          {
            id: 2,
            title: t("footer.footer_contact.apply_here"),
            link: ``,
            isClick: `apply`,
          },
          {
            id: 3,
            title: t("footer.footer_contact.adval"),
            link: `https://adval.jp/`,
          },
          {
            id: 4,
            title: t("footer.footer_contact.code"),
            isClick: false,
            link: ``,
          },
          {
            id: 5,
            title: t("footer.footer_contact.address"),
            isClick: false,
            link: ``,
          },
          {
            id: 6,
            title: t("footer.footer_contact.accommodation"),
            isClick: false,
            link: ``,
          },
        ],
      },
    ]);

    const listFollow = reactive([
      {
        id: 1,
        name: "Facebook",
        socialIcon: "fa-facebook-square",
        link: `https://www.facebook.com/share/Q5aKgPQ42TvA6mQh/?mibextid=LQQJ4d`,
      },
      {
        id: 2,
        name: "Instagram",
        socialIcon: "fa-instagram",
        link: `https://www.instagram.com/timesharing_gladepark/`,
      },
      {
        id: 3,
        name: "X(Twitter)",
        socialIcon: "fa-twitter-square",
        link: `https://x.com/_timesharing?s=21`,
      },
      {
        id: 4,
        name: "LINE",
        socialIcon: "fa-line",
        link: `https://page.line.me/127avfgd?openQrModal=true`,
      },
      // {
      //  id: 5,
      //  name: "TikTok",
      //  socialIcon: "fa-brands fa-tiktok",
      //  link: `https://www.tiktok.com/@time.sharing4?_t=8rVixoyqAoa&_r=1`,
      // },
    ]);

    const dialogApply123 = ref(false);
    const goToApply = () => {
      dialogApply123.value = true;
    };

    const closeModalApply = () => {
      dialogApply123.value = false;
      uStore.isShowModalSearch = false;
    };
    return { listContact, listFollow, goToApply, closeModalApply, dialogApply123};
  },
};
</script>

<style lang="scss" scoped>
a.disabled {
  pointer-events: none;
  cursor: default;
}
@import "./footer.scss";
$XS: 600px;
$SM: 960px;
$MD: 1200px;
.no-hover:hover {
  text-decoration: none;
  cursor: default;
}
.footer-mb {
  display: none;
}
@media all and (max-width: $XS) {
  .footer-contact {
    flex-wrap: wrap;
  }
  .footer-contact-block {
    width: 100%;
    text-align: center;
  }
  .footer-mb {
    display: block;
  }
  .footer-pc {
    display: none;
  }
  .activeMb {
    font-size: 16px;
  }
}
</style>
