<template>
  <div class="footer-follow mb-2">
    <div class="mb-2 text-center">
      <span>FOLLOW US</span>

    </div>
    <ul class="flex justify-center">
      <li v-for="(iconItem, index) in listFollow" class="footer-follow__icon" :key="index">
        <a target="_blank" :href="iconItem.link" :aria-label="iconItem.name">
          <i v-if="iconItem.id != 3" :class="['fab', iconItem.socialIcon]"></i>
          <i v-if="iconItem.id == 3"><svg width="28" height="32" viewBox="0 0 28 32" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_5678_25)">
                <path
                  d="M11.2849 13.4268L1.45474 2H3.78418L12.3197 11.9217L19.137 2H27L16.6909 17.0034L27 28.9861H24.6704L15.6567 18.5085L8.45709 28.9861H0.594131L11.2855 13.4268H11.2849ZM14.4756 17.1356L15.5201 15.6416L23.8311 3.75367H20.253L13.5459 13.3476L12.5014 14.8416L3.78308 27.3122H7.36116L14.4756 17.1362V17.1356Z"
                  fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_5678_25">
                  <rect width="26.4059" height="27" fill="white" transform="matrix(-1 0 0 1 27 2)" />
                </clipPath>
              </defs>
            </svg>
          </i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FooterFollowUs",
  props: {
    listFollow: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./FooterFollowUs.scss";

a {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}

.footer-follow__icon {
  i {
    font-size: 32px;
  }
}
</style>
