<template>
  <div class="wrapper">
    <!-- <div class="common-call-wrap " :class="[ isModelShow ? 'd-none' : '']">
        <button class="contact-note-section">
            <img class="cross" @click="openInfoModal" :src="closeBtn" alt="" srcset="" />
        </button>
      <div class="title">
        使いたいスペースが空いてない！<br />
        そんな時はご連絡ください！
        <div class="time-support">受付時間 10:00 〜 18:00</div>
      </div>
      <div class="call-wrap">
        <div class="call-pc">
          <p class="call-title">予約前の方</p>
          <div class="number">03-6800-8931</div>
        </div>
        <div class="call-pc">
          <p class="call-title">予約後の方</p>
          <div class="number">03-6800-8932</div>
        </div>
      </div>
    </div> -->
    <!-- <div class="message-round-btn-section">
        <button
        class="message-btn-image"
        :class="[isModelShow ? 'd-none' : '']"
        @click="openInfoModal"
      >
        <img class="messageImg" :src="messageBtn" alt="" srcset="" />
        <span>電話で相談する</span>
      </button>
    </div> -->

    <!-- <div class="new-message-main-section d-none">
        <button class="new-chat-btn-image" @click="openChat">
            <img class="messageImg" :src="newChatBtn" alt="" srcset="" />
        </button>
        <button class="new-message-btn-image"
            :class="[isModelShow ? '' : 'd-none']"
            @click="openInfoModal">
            <img class="messageImg" :src="newMessageBtn" alt="" srcset="" />
        </button>
    </div> -->

    <div class="message-main-section">
        <img class="messageback" :src="chatOrPhone" alt="" srcset="" :class="[isModelShow ? 'd-none' : '']" @click="openInfoModal" />
    </div>

    <div class="message-main-box-section" :class="[ isModelShow ? '' : 'd-none']">
        <button class="message-main-box-close">
            <img class="cross" @click="openInfoModal" :src="newClose" alt="" srcset="" />
        </button>
       <div class="message-main-contain-section">
        <div class="message-main-text-section">
          <!-- <h6><span>お困りですか？</span><br/>
              <span>自動チャットで</span><br/>
              <span>疑問にお答えします !</span>
          </h6> -->
          <img :src="chatText" class="message-main-text-img" alt="">
          <button class="message-main-highlite-section">
              <div class="message-bg-highlite" @click="openChat"></div>
              <p @click="openChat">チャットを開始</p>
        </button>
        </div>
       </div>
       <div class="message-second-section">
        <div class="message-second-media-section">
          <button class="message-second-btn-section">予約前の方</button>
          <h1>03-6800-8931</h1>
        </div>
        <div class="message-second-media-section">
          <button class="message-second-btn-section">予約後専用</button>
          <h1>03-6800-8932</h1>
        </div>
       </div>
       <div class="message-third-section">
        電話受付時間 10:00 〜 18:00
       </div>
    </div>

    <div
      id="back-button-position"
      class="contact-mobile d-none d-sm-none"
      :class="{ 'd-block': isShowMobileContact }"
      v-scroll="onScroll"
    >
      <div class="button-container">
        <!-- <div class="dialog-button" @click="isShowDialog = true">
          電話相談・問い合わせ
        </div> -->
        <!-- <div class="dialog-button new-dialog-button" @click="isShowDialog = true">
            <img class="messageImg" :src="newResponsiveMessageBtn" alt="" srcset="" />
        </div> -->
        <div class="dialog-button new-dialog-button" @click="isShowDialog = true">
            <span>チャットで相談</span>&nbsp;
            <img class="messageImg" :src="chatIcon" alt="" srcset="" />
            <span>・&nbsp;電話で相談</span>&nbsp;
            <img class="messageImg" :src="callIcon" alt="" srcset="" />
        </div>
      </div>
    </div>
    <InquiriesDialog
      :showDialog="isShowDialog"
      @closeDialog="isShowDialog = false"
    />
  </div>
</template>
<script setup>
import InquiriesDialog from "@/client/organisms/LayoutHome/ContactInfo/InquiriesDialog.vue";
import { onMounted, ref } from "vue";
// import closeBtn from "@/admin/assets/images/close.png";
// import newMessageBtn from "@/admin/assets/images/message-btn.png";
// import newChatBtn from "@/admin/assets/images/new-chat-Btn.png";
// import newBackBtn from "@/admin/assets/images/back-message-Btn.png";
import newClose from "@/admin/assets/images/new-close.png";
import chatOrPhone from "@/admin/assets/images/chat-or-phone.png";
import chatText from "@/admin/assets/images/chat-text.png";
import chatIcon from "@/admin/assets/images/chat-icon.svg";
import callIcon from "@/admin/assets/images/call-icon.svg";

const isShowMobileContact = ref(false);
const isShowDialog = ref(false);
const isModelShow = ref(true);
const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
const openChat = async () => {
    /* eslint-disable */
    await ChatplusScript.openChat();
    await sleep(400);
    document.querySelector('chat').classList.add('is_sticky')
    //   document.querySelector('#chatplusview.float.chatplusview-modern').classList.remove('closed')
    document.querySelector('#chatplusview.chatplusview-modern').classList.remove('closed')
};

// Initialize modal visibility from cookies
// onMounted(() => {
//   const cookieValue = getCookie("isModelShow");
//   isModelShow.value = cookieValue === null ? true : cookieValue === "true";
// });

onMounted(() => {

  const lastLoadTime = getCookie("lastPageLoadTime");
  const isModelShowCookie = getCookie("isModelShow");
  const currentTime = new Date().getTime();
  const previousUrl = sessionStorage.getItem('previousUrl');

  // Attach a click event to all links on the page.
    document.querySelectorAll('a, button').forEach(link => {
    link.addEventListener('click', () => {
        // Set a flag in sessionStorage when a link is clicked.
        sessionStorage.setItem('linkClicked', 'true');
    });
    });

  // Check if the user is returning from an external site or opening in a new tab
  if (previousUrl == "/" && performance.navigation.type == 0 && performance.getEntriesByType("navigation")[0]?.type == "navigate") {
        if (sessionStorage.getItem('linkClicked') === 'true') {
            isModelShow.value = isModelShowCookie === "true"; // Restore previous state
            sessionStorage.removeItem('linkClicked');
        } else {
            isModelShow.value = true; // Open new tab
        }
  } else {
    // Check if 30 minutes have passed
    if (!lastLoadTime || currentTime - parseInt(lastLoadTime) > 30 * 60 * 1000) {
      isModelShow.value = true;
    } else {
      isModelShow.value = isModelShowCookie === "true"; // Restore previous state
    }
  }

  setCookie("lastPageLoadTime", currentTime, 30);
  setCookie("isModelShow", isModelShow.value, 30);
});

const onScroll = (e) => {
  const scrollDistance = e.target
    .getElementsByClassName("v-main")[0]
    .getBoundingClientRect().top;
  if (scrollDistance < 0) {
    isShowMobileContact.value = true;
  } else {
    isShowMobileContact.value = false;
  }
};
const openInfoModal = () => {
  isModelShow.value = !isModelShow.value;
  setCookie("isModelShow", isModelShow.value, 30);
};
</script>
<style lang="scss" scoped>
.wrapper{
  z-index: 998;
}
.common-call-wrap {
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  z-index: 9999;
  border: solid 2.5px #297fca;
  text-align: center;
  background: #fff;
  .contact-note-section{
    width: 10px;
    position: absolute;
    border-radius: 100%;
    right: 4px;
    top: 4px;
  }
  p {
    padding: 3px 0;
    font-size: 13px;
    background: linear-gradient(90deg, #0b85aa 0%, #0a50c9 100%);
    border-radius: 5px;
  }
  .title {
    font-size: 13px;
    font-weight: bold;
    background: linear-gradient(90deg, #0b85aa 0%, #0a50c9 100%);
    padding: 13px 10px;
    color: #fff;
    border-radius: 5px 5px 0 0;

    .time-support{
      font-weight: normal;
      font-size: 13px;
    }
  }
  .call-pc {
    color: #ffffff;
    text-align: center;
    margin-bottom: 5px;
    .number {
      font-size: 18px;
      margin-top: 3px;
      font-weight: bold;
      color: #0062c5;
    }
  }
  .call-wrap{
    width: 80%;
    margin: 10px auto 0;
  }
}
.message-round-btn-section {
  .message-btn-image {
    border-radius: 4px 0px 0px 4px;
    border: 2px solid #007ec5;
    padding: 10px 10px;
    background: #fff;
    position: fixed;
    right: 10px;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    gap: 10px;
    width: 60px;
    span {
      color: #0062c5;
      text-align: center;
      font-size: 19px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }
}
.new-message-main-section{
    .new-chat-btn-image{
        position: fixed;
        right: 10px;
        bottom: 90px;
    }
    .new-message-btn-image{
        position: fixed;
        right: 10px;
        bottom: 10px;
    }
}
.message-main-section{
  width: auto;
  position: fixed;
  right: 0px;
  bottom: 10px;
  img {
    width: 40px;
    cursor: pointer;
  }
}
.message-main-box-section{
    position: fixed;
    right: 10px;
    bottom: 10px;
    border: 2px solid #297FCA;
    border-radius: 18px;
    background: #daf6ff;
  .message-main-box-close{
    width: 20px;
    position: absolute;
    border-radius: 100%;
    right: 4px;
    top: -28px;
    z-index: 9;
  }
  width: 330px;
  .message-main-contain-section{
    width: 100%;
    background-image: url("@/admin/assets/images/message-main-bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    border-radius: 15px 15px 0 0;
    .message-main-text-section{
      display: flex;
      justify-content: space-between;
      padding: 8px 5px;
      gap: 10px;
      border-radius: 10px 10px 0px 0px;
      .message-main-text-img {
        width: 185px;
      }
      h6{
        span{
          background: #FEDC5E;
          font-size: 16px;
          color: #0A50C9;
          font-weight: 700;
        }
      }
      .message-main-highlite-section{
        height: 0px;
        .message-bg-highlite{
          width: 100%;
          background-image: url("@/admin/assets/images/girls-bg.png");
          background-size: contain;
          background-repeat: no-repeat;
          height: 120px;
          position: relative;
          top: -40px;
        }
        p{
          font-size: 12px;
          border: 1px solid #fff;
          border-radius: 8px;
          padding: 5px 12px;
          margin: 0px;
          color: #fff;
          font-weight: 700;
          position: relative;
          top: -75px;
          background: linear-gradient(90deg, #0b85aa 0%, #0a50c9 100%);
        }
      }
    }
  }
  .message-second-section{
    background-color: #fff;
    padding: 10px 5px;
    display: flex;
    flex-flow: column;
    row-gap: 5px;
    .message-second-media-section{
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      letter-spacing: 2px;
      .message-second-btn-section{
        background: linear-gradient(90deg, #0b85aa 0%, #0a50c9 100%);
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        outline: unset;
        border: unset;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: text;
      }
      h1{
        color: #0062C5;
        font-size: 21px;
        font-weight: 900;
      }
    }
  }
  .message-third-section{
    background: linear-gradient(90deg, #0b85aa 0%, #0a50c9 100%);
    font-size: 16px;
    color: #fff;
    font-weight: 800;
    text-align: center;
    border-radius: 0px 0px 10px 10px;
    padding: 5px 0px 2px;
  }
}

.wrapper {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
}
.contact-mobile {
  color: #fff;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index: 1;
  margin-top: -82px;
  .button-container {
    width: 95%;
    max-width: 100%;
    margin: auto;
    padding: 20px 0px 5px 0px;
    .dialog-button {
      width: 100%;
      color: #fff;
      border-radius: 8px;
      font-weight: bold;
      margin-bottom: 5px;
      padding: 10px 5px;
      font-size: 18px;
      display: block;
      text-align: center;
      background: linear-gradient(90deg, #0b85aa 0%, #0a50c9 100%);
      box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.6);
    }
    .new-dialog-button{
        display: flex;
        align-items: center;
        // gap: 5px;
        justify-content: center;
        span{
          font-size: 14px;
          font-weight: bold;
          color: #fff;
        }
        img{
          width: 23px;
          height: 23px;
          object-fit: contain;
        }
    }
  }
}
.contact-inquiries {
  background: #fff;
  padding: 35px 0 20px;
  position: relative;
  border-radius: 4px;
  max-width: 373px;
  button {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    border-radius: 0%;
    background: rgba(0, 0, 0, 0.4);
    &::before {
      font-weight: bold;
      content: "x";
      color: #fff;
      font-size: 20px;
    }
  }
  .inquiries-button-wrap {
    width: 95%;
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    .inquiries,
    .tel {
      width: 80%;
      margin: 5px auto;
      color: #fff;
      border-radius: 4px;
      padding: 10px;
      display: block;
      background: linear-gradient(
        90deg,
        rgba(11, 133, 170, 0.8) 0%,
        rgba(86, 216, 157, 0.8) 100%
      );
      text-decoration: none;
      text-align: center;
      font-size: 16px;
    }
    .tel {
      position: relative;
      text-align: left;
      font-weight: bold;
      background: linear-gradient(
        90deg,
        rgba(11, 133, 170, 0.8) 0%,
        rgba(10, 80, 201, 0.8) 100%
      );
      &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        content: "";
        background-image: linear-gradient(to left, #30cfd0 0%, #330867 100%);
      }
    }
  }
}
.my-dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media(max-width:575px){
    .message-main-box-section,
    .message-round-btn-section,
    .new-message-main-section,
    .message-main-section,
    .common-call-wrap{
        display: none !important;
    }
}
</style>
