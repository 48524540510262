<template>
  <div class="modal-reservation"  @click.self="closePopup">
    <div class="modal-box">
      <div class="header">
        <div class="title" @click="test123">
          {{ notificationDetail?.title }}
        </div>
        <span class="close-btn" @click="closePopup">
          <i class="fa fa-remove"></i>
        </span>
      </div>
      <div class="body">
        <div class="contents" v-html="notificationDetail?.contents">
        </div>

        <div v-for="(imgitem, index) in notificationDetail.images" :key="index" class="image-wrapper">
          <img class="image-detail" :src="imgitem.img_url" />
          <div v-if="imgitem.img_alt">
            {{ imgitem.img_alt }}
          </div>
        </div>
      </div>
      <div class="date">
        {{ moment(notificationDetail?.updated_at).format("YYYY/M/D") }}
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
export default {
  emits: ["onClose"],
  methods: {
    closePopup() {
      this.$emit("onClose");
    },
  },
  props: {
    notificationDetail: {
      type: Object,
      required: true,
      default: () => { },
    }
  },

  setup(props) {
    const { notificationDetail } = props;
    return {
      notificationDetail,
      moment
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-reservation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  overscroll-behavior: contain;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  touch-action: none;
  z-index: 999;

  &::before,
  &::after {
    content: "";
    width: 1px;
    height: calc(100vh + 1px);
    display: flex;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .modal-box {
    border-radius: 4px;
    background: #fff;
    width: 100%;
    border: 1px solid #dcdcdc;
    max-height: calc(100vh - 150px);
    overflow-y: scroll;

    .header {
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dcdcdc;

      .title {
        font-size: 18px;
        font-weight: 700;
      }

      .close-btn {
        cursor: pointer;

        i {
          font-size: 24px;
        }
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 12px;
      color: #000;
      font-size: 18px;
      font-style: normal;
      line-height: normal;

      .contents {
        margin-top: 0;
        margin-bottom: 10px;
        white-space: pre-line;
      }
    }

    .date {
      text-align: end;
      padding: 12px;
    }

    .image-wrapper {
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: center;
    }

    .image-detail {
      width: 100%;
      object-fit: contain;
    }
  }
}

.modal-reservation .modal-box {
  width: 50%;
}

@media screen and (max-width: 1280px) {
  .modal-reservation .modal-box {
    width: 650px;
    max-width: calc(100vw - 20px);
  }
}
</style>